<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="detail">
        <div class="content">
            <!-- 反馈处理详情开始 -->
            <title-c textc="反馈处理详情"></title-c>
            <div class="d-item">
                <div class="top">
                    <p class="combination">
                        <span>反馈单号:</span>
                        <span>{{ detailData.feedback_number }}</span>
                    </p>
                    <p class="combination">
                        <span>反馈时间:</span>
                        <span>{{ detailData.feedback_date | rTime }}</span>
                    </p>
                    <p class="longtexts">
                        <span>作品名称:</span>
                        <span class="long">{{ detailData.works_name }}</span>
                    </p>
                </div>
                <p class="longtext">
                    <span>投诉产品:</span>
                    <span class="long">
                        <i :style="{backgroundImage: 'url(' + Bg2 + ')'}"></i>
                        {{ gCype(detailData.complaint_type) }}
                    </span>
                </p>
                <p class="longtext">
                    <span>投诉问题描述:</span>
                    <span class="long">{{ detailData.description }}</span>
                </p>
                <p class="combination">
                    <span>处理状态:</span>
                    <span :class="detailData.deal_status | changeColor">
                        {{ detailData.deal_status === 1 ? '处理中' : '已受理' }}
                    </span>
                </p>
                <p class="combination">
                    <span>处理详情:</span>
                    <span :class="detailData.deal_status | changeColor">{{ detailss }}</span>
                </p>
                <p class="combination">
                    <span>原版标题:</span>
                    <span>{{ detailData.actual_name }}</span>
                </p>
                <p class="combination">
                    <span>原版链接:</span>
                    <span>{{ detailData.actual_url }}</span>
                </p>
            </div>
            <div class="de-table">
                <p class="tab-title">
                    链接详情
                </p>
                <el-table
                    v-if="tabledata.length"
                    :data="tabledata"
                    stripe
                    :style="tabledata[0].status ? 'width: 650px;' : 'width: 550px;'"
                    max-height="430"
                    :row-class-name="tableRowClass"
                >label
                    <el-table-column
                        v-for="item in tableColomn"
                        :key="item.key"
                        :prop="item.key"
                        :label="item.name"
                        :width="item.key | table"
                    >
                        <template slot-scope="{row}">
                            <div v-if="item.key === 'url_address'">
                                <a target="_blank" :href="row.url_address">
                                    <p class="ellipsis">{{ row.url_address }}</p>
                                </a>
                            </div>
                            <div v-if="item.key === 'link_name'">
                                {{ row[item.key] }}
                            </div>
                            <div v-if="item.key === 'status'" :class="row | changeColor2">
                                {{ row[item.key] }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-show="tabledata.length >= 9" class="box"></div>
            </div>
            <!-- 反馈处理详情结束 -->
            <!-- 主体信息开始 -->
            <title-c textc="主体身份信息"></title-c>
            <el-row
                v-if="detailData.user_type === 2"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8">
                    <span>投诉人帐号主体类型:</span>
                    <span>组织</span>
                </el-col>
                <el-col :span="8" :title="detailData.user_name">
                    <span>名称:</span>
                    <span>{{ detailData.user_name }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="detailData.user_type === 1"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8">
                    <span>投诉人帐号主体类型:</span>
                    <span>个人</span>
                </el-col>
                <el-col :span="8" :title="detailData.user_name">
                    <span>姓名:</span>
                    <span>{{ detailData.user_name }}</span>
                </el-col>
            </el-row>
            <el-row type="flex" class="row-bg">
                <el-col>
                    <p class="img">
                        <span>证件原件:</span>
                        <ul>
                            <li
                                v-for="item in detailData.pics_url"
                                v-show="detailData.user_type === 1"
                                :key="item"
                            >
                                <viewer :images="detailData.pics_url">
                                    <img :src="item" alt="">
                                </viewer>
                            </li>
                            <li v-show="detailData.user_type === 2">
                                <viewer :images="[detailData.license_url]">
                                    <img :src="detailData.license_url" alt="">
                                </viewer>
                            </li>
                        </ul>
                    </p>
                </el-col>
            </el-row>
            <el-row
                v-if="detailData.user_type === 2"
                type="flex"
                class="row-bg"
                justify="space-between"
            >
                <el-col :span="8">
                    <span>证件有效期:</span>
                    <span>{{ detailData.card_start_date | rTime }} - {{ detailData.card_end_date | rTime }}</span>
                </el-col>
                <el-col :span="8" :title="detailData.legal_person">
                    <span>法人代表姓名:</span>
                    <span>{{ detailData.legal_person }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="detailData.user_type === 2"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8" :title="detailData.contact_name">
                    <span>联系人:</span>
                    <span>{{ detailData.contact_name }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="14">
                    <span>邮箱:</span>
                    <span>{{ detailData.email }}</span>
                </el-col>
                <el-col :span="8">
                    <span>手机号:</span>
                    <span>{{ detailData.mobile_phone }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col
                    v-if="detailData.user_type === 1"
                    :title="detailData.id_card"
                >
                    <span>身份证号码:</span>
                    <span>{{ detailData.id_card }}</span>
                </el-col>
                <el-col
                    v-if="detailData.user_type === 2"
                    :title="detailData.company_code"
                >
                    <span>证件编号:</span>
                    <span>{{ detailData.company_code }}</span>
                </el-col>
            </el-row>
            <!-- 主体信息结束 -->
            <!-- 权属信息开始 -->
            <title-c textc="权属信息"></title-c>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8">
                    <span>权属身份类型:</span>
                    <span>{{ detailData.owner_type === 1 ? '权利人' : '代理人' }}</span>
                </el-col>
                <el-col :span="8" :title="detailData.works_name">
                    <span>作品名称:</span>
                    <span>{{ detailData.works_name }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8">
                    <span>作品类型:</span>
                    <span>{{ gType(detailData.works_category + '') }}</span>
                </el-col>
                <el-col :span="8">
                    <span>授权有效期:</span>
                    <span>{{ detailData.works_start_date | rTime }} - {{ detailData.works_end_date | rTime }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
                v-if="detailData.works_category === 1"
            >
              <el-col :span="8" :title="detailData.pseudonym">
                <span>作者名称/笔名:</span>
                <span>{{ detailData.pseudonym ? detailData.pseudonym : '- -' }}</span>
              </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col>
                    <p class="image">
                        <span>权属证明:</span>
                        <ul>
                            <li
                                v-for="(value, key) in detailData.works_certificate_url"
                                :key="key"
                            >
                                <p>{{ key }}</p>
                                <viewer :images="value">
                                    <img
                                        v-for="items in value"
                                        :key="items"
                                        :src="items"
                                        alt=""
                                    >
                                </viewer>
                            </li>
                        </ul>
                    </p>
                </el-col>
            </el-row>
            <title-c v-if="detailData.owner_type !== 1" textc="授权信息"></title-c>
            <el-row
                v-if="detailData.owner_type !== 1"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="8">
                    <span>权利人名称:</span>
                    <span>{{ detailData.ownership_contact_name }}</span>
                </el-col>
                <el-col :span="8">
                    <span>权利人电话:</span>
                    <span>{{ detailData.ownership_mobile_phone }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="detailData.owner_type !== 1"
                type="flex"
                class="row-bg"
            >
                <el-col>
                    <span>权利人邮箱:</span>
                    <span>{{ detailData.ownership_email }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="detailData.owner_type !== 1"
                type="flex"
                class="row-bg"
            >
                <p class="image">
                    <span>证件原件:</span>
                    <ul>
                        <li>
                            <viewer :images="detailData.owner_url">
                                <img
                                    v-for="item in detailData.owner_url"
                                    :key="item"
                                    :src="item"
                                    alt=""
                                >
                            </viewer>
                        </li>
                    </ul>
                </p>
            </el-row>
            <el-row
                v-if="detailData.owner_type !== 1"
                type="flex"
                class="row-bg"
            >
                <p class="image">
                    <span>授权证明:</span>
                    <ul>
                        <li>
                            <viewer :images="detailData.authorization_url">
                                <img
                                    v-for="item in detailData.authorization_url"
                                    :key="item"
                                    :src="item"
                                    alt=""
                                >
                            </viewer>
                        </li>
                    </ul>
                </p>
            </el-row>
            <!-- 权属信息结束 -->
        </div>
    </div>
</template>
<script>
import TitleC from '@/components/publicComponents/TitleC.vue';
import PTable from '@/components/publicComponents/PTable.vue';
import { getDetail } from '@/api/getData.js';
import { getKey2 } from '@/utils/public.js';
import product from '@/assets/data/product.js';
export default {
    components: {
        TitleC,
        // eslint-disable-next-line vue/no-unused-components
        PTable,
    },
    data() {
        return {
            detailId: null,
            tabledata: [],
            tableName: [
                {
                    name: '链接名称',
                    key: 'link_name',
                },
                {
                    name: '链接地址',
                    key: 'url_address',
                },
                {
                    name: '处理结果',
                    key: 'status',
                },
            ],
            detailData: {},
            show: false,
            Bg2: null,
        };
    },
    computed: {
        detailss() {
            return this.detailData.details === null ? '你的反馈我们已收到，请耐心等待结果...' : this.detailData.details;
        },
        tableColomn() {
            const unStatus = [
                {
                    name: '链接名称',
                    key: 'link_name',
                },
                {
                    name: '链接地址',
                    key: 'url_address',
                },
            ];
            const status = [
                {
                    name: '链接名称',
                    key: 'link_name',
                },
                {
                    name: '链接地址',
                    key: 'url_address',
                },
                {
                    name: '处理结果',
                    key: 'status',
                },
            ];
            return this.tabledata[0].status === '' ? unStatus : status;
        },
    },
    methods: {
        tableRowClass(e) {
            if (e.row.status === '不违规') {
                return 'pending';
            } else if (e.row.status === '已处理') {
                return 'success';
            } else if (e.row.status === '已失效') {
                return 'blur';
            }
        },
        gCype(type) {
            let obj = {};
            product.forEach((item) => {
                obj[item.value] = item.label;
            });
            for (var key in obj) {
                if (type + '' === key) {
                    return obj[key];
                }
            }
        },
        gType(type) {
            let obj = this.$store.getters.getOptionsObj; // 获取作品类型列表
            for (var key in obj) {
                if (type === key) {
                    return obj[key];
                }
            }
        },
    },
    mounted() {
        this.detailId = this.$route.query.id;
        var { feedbackOrder, feedbackId, token } = this.$route.query;
        getDetail(
            {
                feedbackOrder,
                feedbackId,
                token,
            },
            this.detailId
        ).then((res) => {
            this.detailData = res.data;
            product.forEach((item) => {
                if (item.value === this.detailData.complaint_type) {
                    this.Bg2 = item.img;
                }
            });
            if (this.detailData.works_certificate_url) {
                this.detailData.works_certificate_url = JSON.parse(this.detailData.works_certificate_url);
                let obj = {};
                for (let key in this.detailData.works_certificate_url) {
                    obj[getKey2(key)] = this.detailData.works_certificate_url[key];
                }
                this.detailData.works_certificate_url = obj;
            }
            this.tabledata = this.detailData.url_list;
            if (this.detailData.url_list.length >= 8) {
                this.show = !this.show;
            }
            this.tabledata.forEach((item) => {
                if (item.status === 1 || item.status === null) {
                    item.status = '';
                } else if (item.status === 2) {
                    item.status = '已处理';
                } else if (item.status === 3) {
                    item.status = '不违规';
                } else if (item.status === 4) {
                    item.status = '已失效';
                }
            });
        });
    },
    filters: {
        changeColor(newValue) {
            switch (newValue) {
                case 1:
                    return 'pending';
                case 2:
                    return 'success';
                case 3:
                    return 'success1';
            }
        },
        table(key) {
            switch (key) {
                case 'url_address':
                    return 400;
                case 'link_name':
                    return 140;
                case 'status':
                    return 100;
            }
        },
        changeColor2(newValue) {
            switch (newValue.status) {
                case '已处理':
                    return 'success';
                case '不违规':
                    return 'red';
                case '已失效':
                    return 'eee';
            }
        },
    },
};
</script>
<style>
.el-table .success .el-table_7_column_30 {
    color: #34c934;
}
.el-table .pending .el-table_7_column_30 {
    color: #e63232;
}
</style>
<style scoped lang='less'>
::v-deep .el-table tbody tr:hover > td {
    background-color: #e6edff !important;
}
/deep/ .el-table th.is-leaf {
    border: none;
}
/deep/ .el-table td {
    border: none;
}
/deep/ .el-table {
    .ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }
}
.el-col {
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
::v-deep .el-table__row div {
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.row-bg {
    min-height: 45px;
    line-height: 45px;
    font-size: 14px;
    color: #383d47;
    span:nth-child(2) {
        margin-left: 10px;
    }
    .img {
        display: flex;
        span {
            display: inline-block;
            width: 60px;
            font-size: 14px;
            color: #383d47;
        }
        ul {
            display: flex;
            flex: 1;
            li {
                margin: 0 10px;
                img {
                    width: 80px;
                    height: 60px;
                    border-radius: 4px;
                }
            }
        }
    }
    .image {
        display: flex;
        span {
            display: inline-block;
            width: 70px;
        }
        ul {
            flex: 1;
            li {
                margin-bottom: 10px;
                img {
                    width: 80px;
                    height: 60px;
                    border-radius: 4px;
                    margin-right: 20px;
                    margin-top: 10px;
                }
            }
        }
    }
}
.de-table {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px dotted #e6e6eb;
    .el-table::before {
        width: unset;
    }
    .tab-title {
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        color: #27272d;
        font-weight: bold;
    }
    position: relative;
    .box {
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(transparent, #fff);
    }
}
.detail {
    text-align: left;
    .content {
        width: 1200px;
        min-height: 100vh;
        background: #fff;
        margin: 0 auto;
        box-sizing: border-box;
        padding-left: 10%;
        padding-top: 20px;
        padding-bottom: 53px;
        padding-right: 10%;
        .de-table {
            .success {
                color: #34c934;
            }
            .red {
                color: red;
            }
            .eee {
                color: #9a9c82;
            }
            /deep/ .el-table__header {
                thead {
                    tr {
                        th {
                            background-color: #f5f7fc;
                            .cell {
                                font-size: 14px;
                                color: #27272d;
                                line-height: 14px;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            .cell {
                                font-size: 14px;
                                color: #27272d;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
        .c-item {
            width: 80%;
            display: flex;
            flex-wrap: wrap;
        }
        .table {
            width: 700px;
        }
        .top {
            display: flex;
        }
        .max {
            width: 560px;
        }
        .longtext {
            width: 600px;
            margin-bottom: 20px;
            font-size: 14px;
            color: #383d47;
            word-break: break-all;
            display: flex;
            span:nth-child(1) {
                display: inline-block;
            }
            .long {
                flex: 1;
                word-break: break-word;
                margin-left: 10px;
            }
            i {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 100% 100%;
                vertical-align: top;
                border: 1px solid rgba(30, 32, 36, 0.1);
                border-radius: 3px;
                margin-right: 3px;
                background-repeat: no-repeat;
            }
        }
        .longtexts {
            width: 200px;
            margin-bottom: 20px;
            font-size: 14px;
            color: #383d47;
            word-break: break-all;
            display: flex;
            span:nth-child(1) {
                display: inline-block;
            }
            .long {
                flex: 1;
                margin-left: 10px;
            }
        }
        .combination {
            min-width: 33%;
            margin-bottom: 20px;
            font-size: 14px;
            color: #383d47;
            :nth-child(2) {
                margin-left: 10px;
            }
            .pending {
                color: #fa8b4b;
            }
            .success1 {
                color: #e63232;
            }
            .success {
                color: #34c934;
            }
            .red {
                color: red;
            }
            .eee {
                color: #9a9c82;
            }
        }
        .comnum {
            margin-right: 20px;
        }
        .long {
            display: flex;
            span:nth-child(1) {
                width: 100px;
            }
        }
        > p {
            font-size: 14px;
            color: #383d47;
        }
        .img {
            display: flex;
            width: 560px;
            span {
                font-size: 14px;
                color: #383d47;
            }
            ul {
                display: flex;
                li {
                    margin: 0 10px;
                    img {
                        width: 80px;
                        height: 60px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>